import React from "react";
import loader from "../assets/loading.gif";

const Loader = () => {
  return (
    <div className="absolute z-10 top-[30%] left-[40%]">
      <img src={loader} alt="" srcSet="" className="" />
    </div>
  );
};

export default Loader;
