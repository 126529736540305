import "./App.css";
import Home from "./routes/Home/Home";
import { NFTStorage, Blob } from "nft.storage";
import { handleSignedTransaction, transactionErrorHandler } from "./handler";
import { apiProvider } from "./service";
import Keyring from "@polkadot/keyring";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const store = async (imgPath, setFileUri) => {
  console.log("-- calling store function --");
  const NFT_STORAGE_TOKEN =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweDBCOUU5MTZDOGU2OTdhMDZGMzZGYjdCYzk3MjkyYjhkOTAwYjdiMGYiLCJpc3MiOiJuZnQtc3RvcmFnZSIsImlhdCI6MTY3NjM1Mzc2OTAwNiwibmFtZSI6IlRlc3QifQ.-IHfBHIZiWvj4rCq09QttcQtobb9MbExUnaqpN08Wqk";
  const client = new NFTStorage({ token: NFT_STORAGE_TOKEN });
  const imageFile = imgPath;
  const cid = await client.storeBlob(imageFile);
  const url = `https://ipfs.io/ipfs/${cid}`;
  setFileUri(url);
};

export const mintNft = async (
  api,
  owner = null,
  collectionId,
  royaltyRecipient = null,
  royaltyAmount = null,
  metadata,
  transferable
) => {
  try {
    if (api === null) return;
    const transaction = api.tx.metahomeNft.mintNft(
      owner,
      collectionId,
      royaltyRecipient,
      royaltyAmount,
      metadata,
      transferable
    );
    const keyring = new Keyring({ type: "sr25519", ss58Format: 2 });
    const alice = await keyring.addFromUri("//Alice");
    await handleSignedTransaction(transaction, alice);
  } catch (error) {
    transactionErrorHandler(error);
  }
};

export const createCollection = async (api, name, maxNFT, symbol) => {
  try {
    if (api === null) return;
    const transaction = await api.tx.metahomeNft.createCollection(
      name,
      maxNFT,
      symbol
    );
    const keyring = new Keyring({ type: "sr25519", ss58Format: 2 });
    const alice = await keyring.addFromUri("//Alice");
    await handleSignedTransaction(transaction, alice);
  } catch (error) {
    transactionErrorHandler(error);
  }
};

export const handleCreateCollection = async (
  collectionName,
  collectionSymbol,
  limitedCollection,
  maxNFT,
  e
) => {
  e.preventDefault();
  if (!limitedCollection) {
    maxNFT = null;
  }
  const api = await apiProvider();
  await createCollection(api, collectionName, maxNFT, collectionSymbol);
};

export const handleMint = async (
  name,
  description,
  collectionId,
  accountId,
  fileUri
) => {
  const metadataObj = {
    name: name,
    description: description,
    image: fileUri,
  };
  const metadataJSON = JSON.stringify(metadataObj);
  // const str = JSON.stringify(metadataJSON);
  const bytes = new TextEncoder().encode(metadataJSON);
  const blob = new Blob([bytes], {
    type: "application/json;charset=utf-8",
  });
  const NFT_STORAGE_TOKEN =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweDBCOUU5MTZDOGU2OTdhMDZGMzZGYjdCYzk3MjkyYjhkOTAwYjdiMGYiLCJpc3MiOiJuZnQtc3RvcmFnZSIsImlhdCI6MTY3NjM1Mzc2OTAwNiwibmFtZSI6IlRlc3QifQ.-IHfBHIZiWvj4rCq09QttcQtobb9MbExUnaqpN08Wqk";
  const client = new NFTStorage({ token: NFT_STORAGE_TOKEN });
  const metadataCid = await client.storeBlob(blob);
  const metadataCidUrl = `https://ipfs.io/ipfs/${metadataCid}`;
  const api = await apiProvider();
  console.log("accountId = " + accountId + "collectionID = " + collectionId);
  mintNft(api, accountId, collectionId, null, null, metadataCidUrl, true);
};

export const notify = (msg, type) => {
  toast[type](msg, {
    position: toast.POSITION.BOTTOM_RIGHT,
  });
};

function App() {
  return (
    <div className="App">
      <Home />
      <ToastContainer />
    </div>
  );
}

export default App;
