import { ApiPromise, WsProvider } from "@polkadot/api";

export const apiProvider = async () => {
  const wsProvider = new WsProvider("wss://social_li_n1.wowlabz.com/");
  const api = await ApiPromise.create({ provider: wsProvider });
  await api.isReady;

  return api;
};

export const getCollectionList = async () => {
  const api = await apiProvider();
  const collectionLimit = await api.query.metahomeNft.collectionIndex();
  const collectionArray = [];
  for (let i = 0; i < collectionLimit; i++) {
    const collectionHex = await api.query.metahomeNft.collections(i);
    const collection = collectionHex.toHuman();
    collection.id = i;
    collectionArray.push(collection);
  }
  return collectionArray;
};
