import React, { useEffect, useState } from "react";
import { handleCreateCollection, handleMint, store } from "../../App";
import loader from "../../assets/loading.gif";
import Loader from "../../components/Loader";
import { getCollectionList } from "../../service";

const Home = () => {
  //Mint NFT
  const [file, setFile] = useState();
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const [collectionId, setCollectionId] = useState(0);
  const [collectionList, setCollectionList] = useState([]);
  const [address, setAddress] = useState(null);
  const [fileUri, setFileUri] = useState("");
  const [activeTab, setActiveTab] = useState("MintNFT");
  const [loading, setLoading] = useState(false);

  //Create Collection
  const [collectionName, setCollectionName] = useState("");
  const [collectionSymbol, setCollectionSymbol] = useState("");
  const [limitedCollection, setLimitedCollection] = useState(0);
  const [maxNFT, setMaxNFT] = useState(5);

  const handleFileChange = (e) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const handleUploadClick = async (e) => {
    e.preventDefault();
    if (!fileUri) {
      return;
    }
    console.log("handle click called");
    // store(file, name, desc, address, setFileUri);
    setLoading(true);
    // console.log(name, desc, collectionId, address, fileUri);
    await handleMint(name, desc, collectionId, address, fileUri);
    setLoading(false);
    console.log(loading);
  };

  useEffect(() => {
    if (file) {
      store(file, setFileUri);
    }
  }, [file]);

  useEffect(() => {
    const initCollectionList = async () => {
      const tempCollectionList = await getCollectionList();
      setCollectionList(tempCollectionList);
    };
    initCollectionList();
  }, []);

  // console.log(typeof limitedCollection);

  return (
    <div className="bg-[#f1f1fe]">
      <div className={loading ? `py-[10px] blur-lg` : `py-[10px]`}>
        <div className="flex items-center font-orbitron font-semibold w-[40%] justify-around mx-auto ">
          <div
            onClick={() => setActiveTab("MintNFT")}
            className={
              activeTab === "MintNFT"
                ? `text-center p-6 bg-gray-800  text-white w-full rounded-tl-xl`
                : "text-center p-6 bg-white text-gray-800 w-full rounded-tl-xl"
            }
          >
            <h2 className="tracking-[0.2em]">Mint NFT</h2>
          </div>
          <div
            onClick={() => setActiveTab("CreateCollection")}
            className={
              activeTab === "CreateCollection"
                ? `text-center p-6 bg-gray-800  text-white w-full rounded-tr-xl`
                : "text-center p-6 bg-white text-gray-800 w-full rounded-tr-xl"
            }
          >
            <h2 className="tracking-[0.2em]">Create Collection</h2>
          </div>
        </div>
        {activeTab === "MintNFT" && (
          <div className="w-[40%] font-orbitron mx-auto bg-white p-8 rounded-b-xl shadow-lg border border-gray-300">
            <form>
              <div className="mb-6">
                <label
                  htmlFor="nft_name"
                  className="block mb-2 text-sm font-semibold tracking-wider text-gray-900"
                >
                  NFT Name
                </label>
                <input
                  type="text"
                  id="nft_name"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                />
              </div>
              <div className="mb-6">
                <label
                  htmlFor="nft_desc"
                  className="block mb-2 text-sm font-semibold tracking-wider text-gray-900 "
                >
                  NFT Description
                </label>
                <input
                  type="text"
                  id="nft_desc"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                  onChange={(e) => setDesc(e.target.value)}
                  value={desc}
                />
              </div>
              <div className="mb-6">
                <label
                  for="collection"
                  className="block mb-2 text-sm font-semibold tracking-wider text-gray-900 "
                >
                  Collection
                </label>
                <select
                  id="collection"
                  value={collectionId}
                  onChange={(e) => setCollectionId(e.target.value)}
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                >
                  {collectionList.map((collection) => (
                    <option value={collection.id}>{collection.metadata}</option>
                  ))}
                </select>
              </div>
              <div className="mb-6">
                <label
                  htmlFor="address"
                  className="block mb-2 text-sm font-semibold tracking-wider text-gray-900 "
                >
                  Polkadot Wallet Address
                </label>
                <input
                  type="text"
                  id="address"
                  value={address}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  onChange={(e) => setAddress(e.target.value)}
                />
              </div>
              <div className="mb-6">
                <label
                  className="block mb-2 text-sm font-semibold tracking-wider text-gray-900"
                  htmlFor="upload_nft"
                >
                  Upload NFT
                </label>
                <input
                  className="block w-full p-2.5 text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none"
                  aria-describedby="upload_nft_help"
                  id="upload_nft"
                  type="file"
                  onChange={handleFileChange}
                />
              </div>
              <div className="mb-6 flex justify-center items-center gap-[5px]">
                <div className="h-[1px] w-[200px] bg-gray-700"></div>
                <p className="font-orbitron text-sm text-center">OR</p>
                <div className="h-[1px] w-[200px] bg-gray-700"></div>
              </div>
              <div className="mb-6">
                <label
                  className="block mb-2 text-sm font-semibold tracking-wider text-gray-900 "
                  htmlFor="upload_file_uri"
                >
                  NFT File URI
                </label>
                <input
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                  aria-describedby="upload_nft_help"
                  id="upload_file_uri"
                  type="text"
                  value={fileUri}
                  onChange={(e) => setFileUri(e.target.value)}
                />
                {!fileUri && file && (
                  <span className="text-[12px] pt-2 flex items-center tracking-wider font-orbitron">
                    <img src={loader} alt="" className="w-5 h-5 mr-2" />
                    Fetching file URI...
                  </span>
                )}
              </div>

              <button
                type="submit"
                onClick={handleUploadClick}
                disabled={!fileUri}
                className="text-white bg-gray-800 hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-300 font-semibold rounded-lg tracking-wider text-sm w-full sm:w-auto px-5 py-2.5 text-center disabled:cursor-not-allowed"
              >
                Mint
              </button>
            </form>
          </div>
        )}
        {activeTab === "CreateCollection" && (
          <div className="bg-[#f1f1fe] h-[100vh]">
            <div className="w-[40%] font-orbitron mx-auto bg-white p-8 rounded-b-xl shadow-lg border border-gray-300">
              <form>
                <div className="mb-6">
                  <label
                    htmlFor="collection_name"
                    className="block mb-2 text-sm font-semibold tracking-wider text-gray-900"
                  >
                    Collection Name
                  </label>
                  <input
                    type="text"
                    id="collection_name"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                    onChange={(e) => setCollectionName(e.target.value)}
                    value={collectionName}
                  />
                </div>
                <div className="mb-6">
                  <label
                    htmlFor="collection_symbol"
                    className="block mb-2 text-sm font-semibold tracking-wider text-gray-900 "
                  >
                    Collection Symbol
                  </label>
                  <input
                    type="text"
                    id="collection_symbol"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    onChange={(e) => setCollectionSymbol(e.target.value)}
                    value={collectionSymbol}
                  />
                </div>
                <div className="mb-6">
                  <label
                    for="collection"
                    className="block mb-2 text-sm font-semibold tracking-wider text-gray-900 "
                  >
                    Limited collection
                  </label>
                  <select
                    id="collectionId"
                    value={limitedCollection}
                    onChange={(e) =>
                      setLimitedCollection(parseInt(e.target.value))
                    }
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  >
                    <option value={0}>No</option>
                    <option value={1}>Yes</option>
                  </select>
                </div>

                <div className={limitedCollection === 1 ? "mb-6" : "hidden"}>
                  <label
                    htmlFor="nft_desc"
                    className="block mb-2 text-sm font-semibold tracking-wider text-gray-900"
                  >
                    Max NFT's
                  </label>
                  <input
                    type="number"
                    id="nft_desc"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    onChange={(e) => setMaxNFT(e.target.value)}
                    value={maxNFT}
                  />
                </div>

                <button
                  type="submit"
                  onClick={(e) =>
                    handleCreateCollection(
                      collectionName,
                      collectionSymbol,
                      limitedCollection,
                      maxNFT,
                      e
                    )
                  }
                  className="text-white bg-gray-800 hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-300 font-semibold rounded-lg tracking-wider text-sm w-full sm:w-auto px-5 py-2.5 text-center disabled:cursor-not-allowed"
                >
                  Create
                </button>
              </form>
            </div>
          </div>
        )}
      </div>
      {loading && <Loader />}
    </div>
  );
};

export default Home;
