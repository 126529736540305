import { web3FromAddress } from "@polkadot/extension-dapp";
import { notify } from "./App";

export const transactionEventHandler = (events, status) => {
  const toastArr = [];

  if (status.isInBlock) {
    events.forEach(({ event: { data, method, section }, phase }) => {
      if (method === "ExtrinsicFailed") {
        notify("Something went wrong!", "error");
      } else if (method === "ExtrinsicSuccess") {
        console.log(`data: ${data}`);
        console.log(`method: ${method}`);
        console.log(`section: ${section}`);
        notify("Successfully minted!", "success");
      }
      toastArr.push(`\n${section}.${method}`);
    });
  } else if (status.isFinalized) {
    console.log("Finalized block hash", status.asFinalized.toHex());
  }
};

export const transactionErrorHandler = (err) => {
  console.log(`Transaction Error: ${err}`);
};

// export const handleSignedTransaction = async (transaction, accountId) => {
//   try {
//     console.log(
//       "trying to transact transaction = ",
//       transaction,
//       " accountid = ",
//       accountId
//     );
//     const injectedAccount = await web3FromAddress(accountId);
//     const signer = injectedAccount.signer;
//     await transaction.signAndSend(
//       accountId,
//       { signer },
//       ({ events = [], status }) => {
//         transactionEventHandler(events, status);
//       }
//     );
//   } catch (error) {
//     transactionErrorHandler(error);
//   }
// };

export const handleSignedTransaction = async (transaction, alice) => {
  try {
    await transaction.signAndSend(alice, ({ events = [], status }) => {
      transactionEventHandler(events, status);
    });
  } catch (error) {
    transactionErrorHandler(error);
  }
};
